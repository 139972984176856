// PopupModal.js
import React, { useState } from "react";

const PopupModal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay" onClick={onClose}>
    
      <div className="popup-content">{children}</div>
    </div>
  );
};

export default PopupModal;
