import { atom, selector } from "recoil";

export const userState = atom({
  key: "userState",
  default: "Chưa đăng nhập",
})

export const IdKhachhangState = atom({
  key: 'hachhangState ',
  default: null,
});
export const NhanvienState = atom({
  key: 'NhanvienState',
  default: null,
});