import React, { useEffect, useState } from "react";

import LuckyWheel from "../components/lucky-wheel";
import PopupModal from "./popup-thongbao";
import zaloIcon from "./images/Icon_of_Zalo.svg";
import { IdKhachhangState, NhanvienState } from "../state";
import { useRecoilState } from "recoil";
import UserCard from "./user-card";
const HomePage = () => {
  const [ReferralCode, SetReferralCode] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [Nhanvien, setNhanvien] = useRecoilState(NhanvienState);
  const [idNguoiDung, setIdNguoiDung] = useRecoilState(IdKhachhangState);
  const [dangNhap, setDangNhap] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const referral_code = queryParams.get("referral_code");
    const id_zalo = queryParams.get("id");

    setIdNguoiDung(id_zalo);

    if (!id_zalo) {
      setDangNhap(true);
    } else {
      setDangNhap(false);
    }
    if (!referral_code) {
      setShowPopup(true);
    }
    SetReferralCode(referral_code);
    setNhanvien(referral_code);
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    fetch("https://luckywheel.superweb.xyz/api/check-user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ referral_code: referralCode }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Nhập mã lời mời thành công") {
          setShowPopup(false);
          setNhanvien(referralCode);
        }
      })
      .catch((error) => {
        console.error("Error sending referral code:", error.data.message);
      });
  };
  const ketnoi = async () => {
    if (Nhanvien) {
    }
    window.location.href =
      "https://luckywheel.superweb.xyz/api/dangnhapzalo?referral_code=" +
      Nhanvien;
  };

  return (
    <div>
      <PopupModal isOpen={dangNhap}>
        <div style={{ padding: "20px", textAlign: "center" }}>
          <div className="title-thongbao">Xin chào</div>
          <div className="title-description">
            Vui lòng đăng nhập Zalo để tiếp tục
          </div>
          <img
            src={zaloIcon}
            alt="Zalo Icon"
            style={{ width: "50px", height: "50px", margin: "10px 0" }}
          />
          <br />
          <button
            onClick={ketnoi}
            style={{
              padding: "10px 20px",
              marginTop: "10px",
              borderRadius: "5px",
            }}
          >
            Kết nối
          </button>
        </div>
      </PopupModal>
      <PopupModal isOpen={showPopup}>
        <div p={6}>
          {" "}
          <div className="title-thongbao">Xin chào </div>
          <div className="title-description">Vui lòng nhập mã giới thiệu</div>
          <form>
            <input
              type="text"
              placeholder="Mã giới thiệu"
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
              style={{
                padding: "10px",
                width: "90%",
                marginBottom: "20px",
                borderRadius: "5px",
              }}
            />
            <button onClick={() => handleSubmit()}>Xác nhận</button>
          </form>
        </div>
      </PopupModal>
      <LuckyWheel />
    </div>
  );
};

export default HomePage;
