import './App.css';
import HomePage from './components';


function App() {
  console.log(0);
  return (
    <div className="App">
      <HomePage/>
    </div>
  );
}

export default App;
